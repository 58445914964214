import React from 'react';

import './Spacer.css';

function Spacer() {
  return (
    <div id="spacer" />
  );
}

export default Spacer;
