import React from 'react';

import './Header.css';

function Header() {
  return (
    <div id="header" className="uppercase">
      marianandre.fr
    </div>
  );
}

export default Header;
